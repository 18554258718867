Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


/**
 * Generate Avatar background color base on name
 * @param name
 * @returns {string}
 */
export const avatarColors = (name) => {

  let charCodes = name
    .split('')
    .map(char => char.charCodeAt(0))
    .join('');

  const colors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#03A9F4",
    "#00BCD4",
    "#00AA55",
    "#4CAF50",
    "#8BC34A",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#009FD4",
    "#B381B3",
    "#939393",
    "#E3BC00",
    "#D47500",
    "#DC2A2A",
    "#072B7F",
    "#26A69A",
    "#9C27B0",
    "#1D1D1D",
    "#21BA45",
    "#C10015",
    "#31CCEC",
    "#F2C037"
  ];

  return colors[charCodes % colors.length];

}

/**
 * This is only for display
 * Do not use for math
 * @param bytes
 * @returns {string}
 */
export const convertBytes = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

  if (bytes == 0) {
    return "n/a"
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

  if (i == 0) {
    return bytes + " " + sizes[i]
  }

  return Math.ceil((bytes / Math.pow(1024, i)).toFixed(1)) + " " + sizes[i];

}

export const uuid = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }

  });

  return array;
};

export const assignForm = (obj1, obj2) => {

  obj2 = deepClone(obj2);

  Object.keys(obj2).forEach(function (key) {
    if (key in obj1) {
      obj1[key] = obj2[key];
    }
  });
  return obj1;
}

export const deepClone = (object) => {
  return JSON.parse(JSON.stringify(object));
}

export const localStorageSpace = () => {
  var allStrings = '';
  for (var key in window.localStorage) {
    allStrings += key;
    if (window.localStorage.hasOwnProperty(key)) {
      allStrings += window.localStorage[key];
    }
  }
  return allStrings ? 3 + ((allStrings.length * 16) / (8 * 1024)) + ' KB' : 'Empty (0 KB)';
};

export const mergeArrayObjects = (a, b, prop) => {
  var reduced = a.filter(function (aitem) {
    return !b.find(function (bitem) {
      return aitem[prop] === bitem[prop];
    });
  });
  return reduced.concat(b);
}

export const openInNewTab = (url) => {
  window.open(url, '_blank').focus();
}


export const setAll = (obj, val) => Object.keys(obj).forEach(k => obj[k] = val);

export const generatePassword = (len = 10) => {
  const alpha = 'abcdefghijklmnopqrstuvwxyz';
  const calpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const num = '1234567890';
  const specials = '!@#$%^&*';
  const options = [alpha, alpha, alpha, calpha, calpha, num, num, specials];
  let opt, choose;
  let pass = "";
  for (let i = 0; i < len; i++) {
    opt = Math.floor(Math.random() * options.length);
    choose = Math.floor(Math.random() * (options[opt].length));
    pass = pass + options[opt][choose];
    options.splice(opt, 1);
  }
  return pass;
}


export const escapeHTML = (unsafeText) => {
  let div = document.createElement('div');
  div.innerHTML = unsafeText;
  return div.innerText.slice(0, 230) + '...';
}


export const deepCompare = (obj1, obj2, ignoredPaths = [], path = '') => {
  // Check if both are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Check if both have the same number of properties (excluding ignored ones)
  const keys1 = Object.keys(obj1).filter(key => !ignoredPaths.includes(path ? `${path}.${key}` : key));
  const keys2 = Object.keys(obj2).filter(key => !ignoredPaths.includes(path ? `${path}.${key}` : key));

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each property deeply
  for (let key of keys1) {
    let fullPath = path ? `${path}.${key}` : key;

    if (!obj2.hasOwnProperty(key) || ignoredPaths.includes(fullPath)) {
      continue;
    }

    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!deepCompare(val1, val2, ignoredPaths, fullPath)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export const getObjectDifferences = (obj1, obj2, path = '') => {
  let differences = {};

  for (let key in obj1) {
    let fullPath = path ? `${path}.${key}` : key;

    if (!obj2.hasOwnProperty(key)) {
      differences[fullPath] = { old: obj1[key], new: undefined };
    } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      let nestedDiffs = getObjectDifferences(obj1[key], obj2[key], fullPath);
      if (Object.keys(nestedDiffs).length > 0) {
        Object.assign(differences, nestedDiffs);
      }
    } else if (obj1[key] !== obj2[key]) {
      differences[fullPath] = { old: obj1[key], new: obj2[key] };
    }
  }

  for (let key in obj2) {
    let fullPath = path ? `${path}.${key}` : key;

    if (!obj1.hasOwnProperty(key)) {
      differences[fullPath] = { old: undefined, new: obj2[key] };
    }
  }

  return differences;
};


export default {
  convertBytes,
  avatarColors,
  localStorageSpace,
  deepClone,
  mapOrder,
  assignForm,
  uuid,
  mergeArrayObjects,
  openInNewTab,
  setAll,
  generatePassword,
  escapeHTML,
  deepCompare,
  getObjectDifferences
}
